import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {HouseOutlined } from "@mui/icons-material";


function NotFound() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100vw",
        height: "100vh",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        background: `linear-gradient(45deg, #0022ff55, #00AA0099)`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "10rem",
            lineHeight: "1.2",
            textAlign: "right",

            background: `linear-gradient(45deg, #0022ff, #00AA00)`,
            backgroundClip: "text",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",

            color: "transparent",
          }}
        >
          404
        </Typography>
        <Typography
          sx={{
            fontWeight: "light",
            fontSize: "2rem",
            lineHeight: "1.2",
            textAlign: "center",

            background: `linear-gradient(45deg, #0022ff, #00AA00)`,
            backgroundClip: "text",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",

            color: "transparent",
          }}
        >
          Seite nicht gefunden
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          startIcon={< HouseOutlined  />}
          sx={{
            mt: "2rem",
            color: "#fff",
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          Zurück zur Startseite
        </Button>
      </Box>
    </Box>
  );
}

export default NotFound;
