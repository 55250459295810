import { Theme } from "@emotion/react";
import { Button, SxProps, Typography } from "@mui/material";
import { CSSProperties } from "react";
import { Link, useNavigate } from "react-router-dom";

function NavButton({
  children,
  to,
  style,
}: {
  children: any;
  to: string;
  style?: SxProps<Theme>;
}) {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(to);
        const element = document.getElementById(to.replaceAll("#", ""));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }}
      variant="contained"
      color="secondary"
      sx={{
        color: "#ffffff",

        transition: "all 0.3s ease-in-out",

        borderRadius: "none",

        "&:hover": {
          transform: "scale(1.1)",
        },
        ...style,
      }}
    >
      {children}
    </Button>
  );
}

export default NavButton;
