import { AppBar, Box, Button, Drawer, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { Menu, DescriptionOutlined, Email } from "@mui/icons-material";
import { CSSProperties, useState } from "react";

// @Component
import NavLink from "./NavLink";
import NavButton from "./NavButton";

const MobileLinkStyle: CSSProperties = {
  height: "60px",
  width: "300px",

  borderBottom: "1px solid #000",

  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

function TopBar() {
  const theme = useTheme();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <AppBar
        position="static"
        sx={{
          height: "100px",
          backgroundColor: "#ffffff",
          color: "#ffffff",

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",

          px: "15%",

          boxShadow: "3px 2px 2px rgba(0, 0, 0, 0.25)",

          "@media screen and (max-width: 768px)": {
            px: "0px",
          },

          "@media screen and (max-width: 1300px)": {
            px: "2px",
          },

          "@media screen and (max-width: 1800px)": {
            px: "10%",
          },
        }}
      >
        <Link to="/">
          <img
            src="/assets/logoWide.png"
            alt="logo"
            style={{
              width: "227px",
              height: "70px",
            }}
          />
        </Link>

        <Box
          sx={{
            flexGrow: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",

            "@media screen and (max-width: 768px)": {
              display: "none",
            },
          }}
        >
          <NavLink to="/#about">Über mich</NavLink>
          <NavLink to="/#contact">Kontakt</NavLink>
          <NavLink to="/#services">Leistungen</NavLink>
        </Box>

        <NavButton
          to="#contact"
          style={{
            "@media screen and (max-width: 1000px)": {
              display: "none",
            },
          }}
        >
          <Email
            fontSize="small"
            sx={{
              mr: "10px",
            }}
          />
          Kontakt
        </NavButton>

        <Button
          variant="contained"
          color="secondary"
          sx={{
            color: theme.palette.secondary.main,

            backgroundColor: "transparent",
            border: "none",
            backdropFilter: "none",
            boxShadow: "none",

            "&:hover": {
              backgroundColor: "transparent",
              border: "none",
              boxShadow: `3px 3px 3px 0px ${theme.palette.secondary.main}`,
            },

            "@media screen and (min-width: 768px)": {
              display: "none",
            },
          }}
          onClick={() => setSidebarOpen(true)}
        >
          <Menu />
        </Button>
      </AppBar>
      <Drawer
        anchor="right"
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        sx={{
          "@media screen and (min-width: 768px)": {
            display: "none",
          },
        }}
        PaperProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "0px",
            backgroundColor: "white",
          },
          onClick: () => setSidebarOpen(false),
        }}
      >
        <Link to="/">
          <img
            src="/assets/logoWide.png"
            alt="logo"
            style={{
              width: "300px",
            }}
          />
        </Link>

        <NavLink to="#about" style={MobileLinkStyle}>
          Über mich
        </NavLink>
        <NavLink to="#contact" style={MobileLinkStyle}>
          Kontakt
        </NavLink>
        <NavLink to="#services" style={MobileLinkStyle}>
          Leistungen
        </NavLink>
      </Drawer>
    </>
  );
}

export default TopBar;
