import { Typography, Button, Box } from "@mui/material";
import {
  DescriptionOutlined,
  BadgeOutlined,
  Email,
  LocationOn,
  Info,
} from "@mui/icons-material";
import { AboutSection } from "../components/AboutSection";
import { AboutSection2 } from "../components/AboutSection2";
import { Footer } from "../components/Footer";
import TopBar from "../components/TopBar";
import { useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { AboutSection3 } from "../components/AboutSection3";
import NavButton from "../components/NavButton";
import AboutSectionGallery from "../components/AboutSectionGallery";

function LandingPage() {
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById(location.hash.replaceAll("#", ""));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  return (
    <>
      <TopBar />
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 100px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/assets/mainBG.png"
          alt=""
          style={{
            height: "100vh",
            width: "100vw",
            position: "absolute",
            top: "50px",
            zIndex: -1,
            filter: "brightness(0.25)",
            objectFit: "cover",
            minWidth: "100vw",
            minHeight: "100vh",
          }}
        />
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bolder",
            fontSize: "7rem",
            letterSpacing: "0.05rem",
            lineHeight: "1.2",
            textAlign: "center",

            "@media (max-width: 1200px)": {
              fontSize: "3rem",
            },
          }}
        >
          PhysioPunkt
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "normal",
            fontSize: "5rem",
            letterSpacing: "0.05rem",
            lineHeight: "1.2",
            textAlign: "center",
            mb: "5px",

            "@media (max-width: 1200px)": {
              fontSize: "3rem",
              mb: "20px",
            },
          }}
        >
          Leerkamp
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "light",
            fontFamily: "Overpass Variable",
            fontSize: "1.25rem",
            textAlign: "center",
          }}
        >
          Praxis für Physiotherapie Leerkamp
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            mt: "20px",
          }}
        >
          <NavButton to="#contact">
            <LocationOn
              fontSize="small"
              sx={{
                mr: "10px",
              }}
            />
            Anfahrt
          </NavButton>
          <NavButton to="#services">
            <Info
              fontSize="small"
              sx={{
                mr: "10px",
              }}
            />
            Leistungen
          </NavButton>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "200px",
          background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bolder",
            fontSize: "2rem",
            letterSpacing: "0.05rem",
            lineHeight: "1.2",
            textAlign: "center",
          }}
        >
          Brauchen Sie einen Termin?
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "light",
            fontFamily: "sans-serif",
            fontSize: "1rem",
            textAlign: "center",
          }}
        >
          Jetzt ganz einfach per Email oder Telefonisch!
        </Typography>

        <NavButton
          to="#contact"
          style={{
            mt: "20px",
          }}
        >
          <Email
            fontSize="small"
            sx={{
              mr: "10px",
            }}
          />
          Termin machen!
        </NavButton>
      </Box>
      <AboutSection />
      <AboutSection3 />
      <AboutSection2 />
      <AboutSectionGallery />
      <Footer />
    </>
  );
}

export default LandingPage;
