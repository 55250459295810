import { Typography } from "@mui/material";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";

function NavLink({
  children,
  to,
  style,
}: {
  children: string;
  to: string;
  style?: CSSProperties;
}) {
  return (
    <Link
      to={to}
      style={style}
      onClick={() => {
        const element = document.getElementById(to.replaceAll("#", ""));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }}
    >
      <Typography fontSize="20px" fontWeight="bold" className="nav-link">
        {children}
      </Typography>
    </Link>
  );
}

export default NavLink;
