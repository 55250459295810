import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function AboutSectionGallery() {
  const images = [
    { img: "20240102_102526.jpg", width: 8 },
    { img: "20240109_105452.jpg", width: 4 },
    { img: "20240109_105745.jpg", width: 4 },
    { img: "20240109_105422.jpg", width: 8 },
    { img: "20240109_105507.jpg", width: 8 },
    { img: "20240109_113352.jpg", width: 4 },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        mt: "60px",
        "@media (max-width: 1200px)": {
          pt: "50px",
        },

        px: "5%",
        py: "5%",
      }}
      id="images"
    >
      <Typography
        sx={{
          fontWeight: "bolder",
          fontFamily: "Lexend Variable",
          fontSize: "4rem",
          letterSpacing: "0.05rem",
          lineHeight: "1.2",
          textAlign: "center",
          width: "100%",
          color: "primary.main",
          "@media (max-width: 600px)": {
            fontSize: "3rem",
          }
        }}
        fontFamily="Lexend Variable"
      >
        Bilder
      </Typography>

      <Box sx={{

      }}>
        <Grid container spacing={2} sx={{ mt: "50px" }}>
          {images.map((image, index) => (
            <Grid item key={index} xl={image.width} lg={image.width} md={4} sm={6} xs={12} sx={{
              "&:hover": {
                filter: "brightness(1) drop-shadow(0 0 0.5rem #000)",
                transition: "all 0.3s ease",
              },
              transition: "all 0.6s ease",
              filter: "brightness(0.9)",
            }}>
              <img
                src={`/assets/einrichtung/${image.img}`}
                alt="gallery"
                style={{
                  width: "100%",
                  height: "50vh",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default AboutSectionGallery;
