import { Box, Grid, Table, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function AboutSection3(): JSX.Element {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "auto",

        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "center",
        alignItems: "center",
        mt: "60px",

        "@media (max-width: 1200px)": {
          pt: "50px",
        },
      }}
      id="contact"
    >
      <Grid
        item
        lg={6}
        md={12}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "10px",

          px: "5%",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bolder",
            fontFamily: "Lexend Variable",
            fontSize: "4rem",
            letterSpacing: "0.05rem",
            lineHeight: "1.2",
            textAlign: "right",
            color: "primary.main",
          }}
          fontFamily="Lexend Variable"
        >
          Termin?
        </Typography>

        <Typography
          sx={{
            fontFamily: "Lexend Variable",
            fontSize: "3rem",
            letterSpacing: "0.05rem",
            lineHeight: "1.2",
            textAlign: "right",
            color: "primary.main",

            mb: "40px",
          }}
          fontFamily="Lexend Variable"
        >
          Ganz Einfach!
        </Typography>

        <Typography
          sx={{
            fontWeight: "bold",
            fontFamily: "Overpass Variable",
            fontSize: "1.4rem",
            textAlign: "right",
          }}
        >
          Termine bitte nur telefonisch!!
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* EMAIL: Uncomment to readd */}
          {/* <Box
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "10px",

              "@media (max-width: 1200px)": {
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "2px",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontFamily: "Overpass Variable",
                fontSize: "1.4rem",
                textAlign: "right",
              }}
            >
              Email
            </Typography>
            <Typography
              sx={{
                fontWeight: "light",
                fontFamily: "Overpass Variable",
                fontSize: "1.4rem",
                textAlign: "right",
              }}
            >
              <Link to="mailto:kontakt@physiopunktleerkamp.de">
                kontakt@physiopunktleerkamp.de
              </Link>
            </Typography>
          </Box> */}

          <Box
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "10px",

              "@media (max-width: 1200px)": {
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "2px",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontFamily: "Overpass Variable",
                fontSize: "1.4rem",
                textAlign: "right",
              }}
            >
              Telefon
            </Typography>
            <Typography
              sx={{
                fontWeight: "light",
                fontFamily: "Overpass Variable",
                fontSize: "1.4rem",
                textAlign: "right",
              }}
            >
              <Link to="tel:05923 9933513">05923 9933513</Link>
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            fontWeight: "light",
            fontFamily: "Overpass Variable",
            fontSize: "1.4rem",
            textAlign: "left",
            mt: "40px",
          }}
        >
          PhysioPunkt Leerkamp <br />
          Föhnstraße 1 (1 OG) <br />
          48465 Schüttorf <br />
          Germany <br />
        </Typography>

        <Typography
          sx={{
            fontWeight: "light",
            fontFamily: "Overpass Variable",
            fontSize: "1.4rem",
            textAlign: "left",
            mt: "40px",
          }}
        >
          Lift vorhanden
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          width: "100%",
          maxWidth: "100%",
          flexBasis: "100%",
          padding: "5%",

          "@media (max-width: 1200px)": {
            padding: "0",
          },
        }}
      >
        <iframe
          title="Anfahrt"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d669.6734457598951!2d7.221121752284062!3d52.32222696611496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b825461f6f0e29%3A0x1a6e631a6513678e!2zRsO2aG5zdHJhw59lIDEsIDQ4NDY1IFNjaMO8dHRvcmY!5e1!3m2!1sde!2sde!4v1699551436208!5m2!1sde!2sde"
          style={{
            aspectRatio: "1/1",
            border: 0,
            width: "100%",
          }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Grid>
    </Grid>
  );
}
