import { Box, Grid, SxProps, Typography } from "@mui/material";
import {
  Apartment,
  ApartmentOutlined,
  Description as DescriptionIcon,
  Newspaper,
  Public,
  Star,
  Start,
} from "@mui/icons-material";
import { Theme } from "@emotion/react";

const iconSX: SxProps<Theme> = {
  width: "25%",
  height: "auto",
  gap: "10px",
  color: "primary.main",
  fontSize: "4rem",
};

export function AboutSection2(): JSX.Element {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        mt: "60px",
        "@media (max-width: 1200px)": {
          pt: "50px",
        },

        px: "5%",
        py: "5%",
      }}
      id="services"
    >
      <Typography
        sx={{
          fontWeight: "bolder",
          fontFamily: "Lexend Variable",
          fontSize: "4rem",
          letterSpacing: "0.05rem",
          lineHeight: "1.2",
          textAlign: "center",
          width: "100%",
          color: "primary.main",
          "@media (max-width: 600px)": {
            fontSize: "3rem",
          }
        }}
        fontFamily="Lexend Variable"
      >
        Behandlungen
      </Typography>

      {/* <Box
        sx={{
          width: "100%",
          height: "auto",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",

          mt: "50px",
        }}
      >

      </Box> */}
      <Grid
        container
        spacing={2}
        sx={{
          width: "100%",
          height: "auto",

          mt: "50px",
        }}
      >
        <InfoCard
          title="Physiotherapie"
          description="Die Physiotherapie (Krankengymnastik) umfasst die äußerliche Anwendung von Heilmitteln mit aktiven und passiven Formen der Bewegungstherapie zur Prävention, Behandlung und Rehabilitation von krankheitsbedingten Funktionsstörungen sowie zur allgemeinen Gesundheitsförderung."
        />
        <InfoCard
          title="Therapie bei cranio - mandibulären Dysfunktion (CMD)"
          description="Bei der CMD – Therapie (Fehlkfunktion der Kopf – Kiefergelenkes) handelt es sich um ein manuelles
Einwirken zur Wiederherstellung des physiologischen Zusammenspiels zwischen Muskulatur,
Gelenken und Nerven."
        />
        <InfoCard
          title="Manuelle Lymphdrainage"
          description="Unter manueller Lymphdrainage versteht man die Aktivierung des Lymphabflusses bzw. die
          Entstauung (Drainage) des Gewebes durch bestimmte physiotherapeutische Techniken."
        />
        <InfoCard
          title="Handtherapie"
          description="Unter Handtherapie versteht man die Rehabilitation von Menschen mit traumatischen, angeborenen
und degenerativen Erkrankungen der Hand."
        />
        <InfoCard
          title="Neurophysiologische Behandlung nach Bobath"
          description="Das Bobath – Konzept ist ein problemlösender Ansatz in der Befundaufnahme und Behandlung von
Menschen mit erworbenen oder angeborenen neurologischen Erkrankungen. Das allgemeine Ziel ist
dabei die Wiedererlangung der motorischen Funktionen sowie die bestmögliche Selbstständigkeit
und Verbesserung der Lebensqualität."
        />
        <InfoCard
          title="Wirbelsäulentherapie nach Dorn Breuss"
          description="Die Wirbelsäulentherapie nach Dorn Breuss ist eine Behandlungsmethode mit sanften, manuellen
Mobilisationstechniken. Die Therapie kann bei Beschwerden des Bewegungsapparates wie z.B.
Bandscheibenproblemen, chronifizierten Rückenbeschwerden, Tennisellenbogen, Hüft- und
Knieproblematik usw. helfen."
        />
        <InfoCard
          title="Schwindel – und Vestibulartherapie"
          description="Schwindel und Gleichgewichtsstörungen können sowohl plötzlich als auch durch bestimmte
Vorerkrankungen auftreten. Als zertifizierte Schwindel – und Vestibulartherapeutin (IVRT) nutze ich
sowohl evidenzbasierte als auch effektive Techniken und Methoden, um diese zu behandeln."
        />
      </Grid>
    </Box>
  );
}

function InfoCard(props: { title: string; description: string }): JSX.Element {
  return (
    <Grid xl={6} item>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "10px",

          width: "100%",

          "&:hover": {
            transform: "scale(1.05)",
            transition: "all 0.3s ease",
          },
          transition: "all 0.6s ease",
          userSelect: "none",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bolder",
            fontFamily: "Lexend Variable",
            fontSize: "1.5rem",
            letterSpacing: "0.05rem",
            lineHeight: "1.2",
            textAlign: "left",
            color: "primary.main",
          }}
          fontFamily="Lexend Variable"
        >
          {props.title}
        </Typography>

        <Typography
          sx={{
            fontWeight: "light",
            fontFamily: "Overpass Variable",
            fontSize: "1.2rem",
            textAlign: "left",
            opacity: 0.8,
          }}
          fontFamily="Overpass Variable"
        >
          {props.description}
        </Typography>
      </Box>
    </Grid>
  );
}
