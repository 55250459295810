import { Box, Typography } from "@mui/material";

export function Footer(): JSX.Element {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",

        mt: "50px",
        px: "20%",
        py: "10px",

        backgroundColor: "primary.main",
        color: "#fff",

        "@media (max-width: 1200px)": {
          px: "40px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "light",
            fontSize: "1rem",
            letterSpacing: "0.05rem",
            lineHeight: "1.2",
            textAlign: "center",
          }}
        >
          <a
            href="https://ipmake.dev"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#fff",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            © 2024 IPGsystems
          </a>{" "}
          <br /> All rights reserved
        </Typography>
      </Box>
    </Box>
  );
}
