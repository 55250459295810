import { Grid, Typography } from "@mui/material";

export function AboutSection(): JSX.Element {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "auto",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        "@media (max-width: 1200px)": {
          pt: "50px",
        },
      }}
      id="about"
    >
      <Grid
        item
        lg={6}
        md={12}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "10px",

          px: "5%",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bolder",
            fontFamily: "Lexend Variable",
            fontSize: "4rem",
            letterSpacing: "0.05rem",
            lineHeight: "1.2",
            textAlign: "left",
            color: "primary.main",
            mb: "20px",
            "@media (max-width: 600px)": {
              fontSize: "3rem",
            }
          }}
          fontFamily="Lexend Variable"
        >
          Über mich
        </Typography>

        <Typography
          sx={{
            fontWeight: "light",
            fontFamily: "Overpass Variable",
            fontSize: "1.4rem",
            textAlign: "left",
            "@media (max-width: 1200px)": {
              ml: "10px",
            }
          }}
          fontFamily="Overpass Variable"
        >
          <strong>Ausbildung und Weiterbildung </strong>
          <li>staatlich anerkannte Physiotherapeutin</li>
          <li>zertifizierte manuelle Lymphdrainage Therapeutin </li>
          <li>zertifizierte Bobath Therapeutin für Erwachsene</li>
          <li>zertifizierte Schwindel - und Vestibulartherapeutin  (IVRT)</li>

          <br />

          <strong>Fortbildungen</strong> 
          <li>Cranio-mandibuläre Dysfunktion (CMD)</li>
          <li>Handtherapie (AFH)</li>
          <li>Kinesiologisches Taping</li>
          <li>Wirbelsäulentherapie nach Dorn - Breuß</li>
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          padding: "5%",
          "@media (max-width: 1200px)": {
            display: "none",
          },
        }}
      >
        <img src="/assets/Birte.jpg" alt="" style={{ width: "100%" }} />
      </Grid>
    </Grid>
  );
}
