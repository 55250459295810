import { Routes, Route } from "react-router-dom";

// @Pages
import LandingPage from "./pages/LandingPage";
import NotFound from "./pages/404";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;