import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

// fonts
import '@fontsource-variable/overpass';
import '@fontsource-variable/lexend';

const theme = createTheme({
  palette: {
    primary: {
      main: "#13ad89",
    },
    secondary: {
      main: "#00ACD3",
    },
  },
  // make the buttons not rounded
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontFamily: "Lexend Variable",
        },
      },
    },
  },
});

export { theme };

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>
);